/* eslint-disable no-restricted-globals */
// import 'vite/modulepreload-polyfill';

import '../css/main.css';

import lazyLoadModule from './utils/loaders/moduleLoader.js';

lazyLoadModule('alpineComponents', 100).then(() => {
  console.log('alpineComponents has been loaded');
});

/**
 * $Panels
 */
const panels = document.querySelectorAll('.panel');
const panelImageSelectors = document.querySelectorAll('.panel-image');
const panelStripSVGSelectors = document.querySelectorAll('.panel-strip-svg');
const homeRegister = document.querySelectorAll('.home-register');
const pageRegister = document.querySelectorAll('.page-register');
const moduleRegister = document.querySelectorAll('.module-register');
const activePanel = document.querySelector('.panel-active');
const overlay = document.querySelector('.panel-wrapper');

const resetAllActivePanels = () => {
  panelImageSelectors.forEach((panelImage) => {
    setTimeout(() => {
      panelImage.classList.remove('!opacity-0');
    }, 1200);
  });
  panels.forEach((panel) => {
    panel.classList.remove('panel-menu');
  });
};

const removeIsActive = () => {
  panels.forEach((panel) => {
    panel.classList.add('panel-menu');
    panel.classList.remove('panel-active');
  });
};

const addSVGStyle = () => {
  panelStripSVGSelectors.forEach((svg) => {
    svg.classList.add('panel-strip-svg-color');
    svg.classList.add('panel-strip-svg-size');
  });
};

const addOverlay = () => {
  overlay.classList.add('overlay');
};

const removeOverlay = () => {
  overlay.classList.remove('overlay');
};
const removeBackgroundHover = (panelCurrent) => {
  const activePanelContentWrapper = panelCurrent.querySelector('.panel-content-wrapper');
  if (activePanelContentWrapper) {
    activePanelContentWrapper.classList.remove('background-hover');
  }
};
const timingRegister = (url) => {
  setTimeout(() => {
    removeOverlay();
    if (url) {
      window.location.href = url;
    }
  }, 2000);
};

const findPanelBySlug = (slug) => {
  let result;
  const prefix = slug.startsWith('/') ? '/' : '';
  panels.forEach((panel) => {
    if (prefix.concat(panel.dataset.url) === slug) {
      result = panel;
    }
  });
  return result;
};

panels.forEach((panel) => {
  panel.addEventListener('click', ($el) => {
    const { currentTarget } = $el;
    if (currentTarget.classList.contains('panel-active')) {
      return;
    }
    addOverlay();
    removeIsActive();
    currentTarget.classList.add('panel-active');
    if (activePanel) {
      const activePanelContent = activePanel.querySelector('.panel-content-wrapper .panel-content');
      if (activePanelContent) {
        activePanelContent.classList.add('max-lg:!hidden');
      }
    }
    setTimeout(() => {
      addSVGStyle();
    }, 1000);
    const { url } = currentTarget.dataset;
    setTimeout(() => {
      removeOverlay();
      removeBackgroundHover(currentTarget);
      window.location.href = url;
    }, 2400);
  });
});

// close btn
panels.forEach((panel) => {
  const $closeBtn = panel.querySelector('.panel-close');
  $closeBtn.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetAllActivePanels();
    addOverlay();
    const $panel = e.currentTarget.closest('.panel');
    if ($panel.classList.contains('panel-active')) {
      $panel.classList.remove('panel-active');
      timingRegister('/');
    }
  });
});

// animation register
homeRegister.forEach((element) => {
  if (!element.hasAttribute('x-on:click')) {
    element.setAttribute('x-on:click', `() => { showContent = false; }`);
  }
  element.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    const targetUrl = e.currentTarget.getAttribute('data-url');
    resetAllActivePanels();
    addOverlay();
    if (activePanel) {
      activePanel.classList.remove('panel-active');
    }
    timingRegister(targetUrl);
    // removeIsActive();
  });
});

pageRegister.forEach((element) => {
  if (!element.hasAttribute('x-on:click')) {
    element.setAttribute('x-on:click', `() => { showContent = false; }`);
  }
  element.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    const targetUrl = e.currentTarget.getAttribute('data-url');
    removeIsActive();
    addOverlay();
    if (activePanel) {
      activePanel.classList.remove('panel-active');
    }
    timingRegister(targetUrl);
  });
});

moduleRegister.forEach((element) => {
  if (!element.hasAttribute('x-on:click')) {
    element.setAttribute('x-on:click', `() => { showContent = false; }`);
  }
  element.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    const targetUrl = e.currentTarget.getAttribute('data-url');
    const panel = findPanelBySlug(targetUrl);
    if (panel) {
      panel.click();
    }
    console.log(panel);
  });
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  // HMR code
  import.meta.hot.accept();
}
